const Button = ({
    type,
    onClick,
    disabled = false,
    full,
    redText = false,
    title,
    customStyle = '',
    bgColor = 'background', //'background, background-primary, background-secondary
    variant = 'solid' // Solid, Ghost, Outline
}) => {
    const findCorrectButtonVersion = () => {
        let bgStyle = ''
        switch (bgColor) {
            case 'background': {
                if (variant === 'solid') bgStyle = 'button-background-solid'
                else if (variant === 'ghost') bgStyle = 'button-background-ghost'
                else bgStyle = 'button-background-outline'
                break
            }
            case 'background-primary': {
                if (variant === 'solid') bgStyle = 'button-background-primary-solid'
                else if (variant === 'ghost') bgStyle = 'button-background-primary-ghost'
                else bgStyle = 'button-background-primary-outline'
                break
            }
            case 'background-secondary': {
                if (variant === 'solid') bgStyle = 'button-background-secondary-solid'
                else if (variant === 'ghost') bgStyle = 'button-background-secondary-ghost'
                else bgStyle = 'button-background-secondary-outline'
                break
            }
            default: {
                bgStyle = 'button-background-solid'
            }
        }

        return bgStyle
    }

    return (
        <button
            type={type}
            onClick={onClick}
            className={`
      ${
          full
              ? `shadow-[0px_30px_40px_15px_rgba(0,0,0,0.3)] w-full text-4xl py-4 text-bg`
              : ` ${
                    customStyle ? customStyle : 'pt-1 pb-2 lg:pt-1 lg:pb-2'
                } leading-none rounded-xl mt-1 pl-4 pr-4  text-2xl tracking-0-03em   lg:text-3xl lg:mt-1  lg:pl-6 lg:pr-6    xl:text-4xl xl:mt-2 xl:pt-2 xl:pb-3 xl:pl-8 2xl:pr-8     2xl:text-4xl 2xl:mt-2 2xl:pt-2 2xl:pb-3 2xl:pl-8 2xl:pr-10`
      } ${
                disabled
                    ? 'button-disabled hover:cursor-default'
                    : `${findCorrectButtonVersion()} ${redText && 'text-turbo-primary'} 
             hover:cursor-pointer`
            }  font-fk_screamer `}
        >
            {title}
        </button>
    )
}
export default Button
