import React, { Fragment } from 'react'
import { transformDay, transformOpeninHourTime } from '@/functions/common/helper'
import Line from '@/ui/icons/Line'

export const OpeningHours = ({ speedloData, data }) => {
    function getFragment(branch, day, openAt, closeAt) {
        return (
            <Fragment key={branch}>
                <div className={'flex justify-center'}>
                    <div
                        className={
                            'text-left min-w-[40px] lg:min-w-[70px] font-libre_franklin text-[12px] lg:text-[16px]'
                        }
                    >
                        {day}
                    </div>
                    <div className={'self-end mx-2 xs:mx-4 mb-1'}>
                        <Line />
                    </div>
                    <div className={'font-libre_franklin_bold  text-left self-end  text-[12px] lg:text-[16px]'}>
                        {openAt} - {closeAt}
                    </div>
                </div>
            </Fragment>
        )
    }

    return (
        <div className={`${speedloData ? 'grid grid-cols-1 lg:grid-cols-2' : ''} lg:gap-x-4`}>
            <div className={'text-center mt-5 lg:mt-0'}>
                <div className={'font-fk_screamer text-[20px] pt-[20px] tracking-0-05em text-center'}>
                    Otevírací doba podniku
                </div>
                <div
                    className={
                        'border-t border-b xs:border border-black rounded-none  xs:rounded-xl mt-5 p-6  flex-row justify-center '
                    }
                >
                    {data &&
                        data.branch.openingHours.map((item, branch) => {
                            return getFragment(
                                branch,
                                item.day,
                                transformOpeninHourTime(item.openAt),
                                transformOpeninHourTime(item.closeAt)
                            )
                        })}
                </div>
            </div>
            {speedloData && speedloData && (
                <div className={'text-center mt-5 lg:mt-0'}>
                    <div className={'font-fk_screamer text-[20px] pt-[20px] tracking-0-05em text-center'}>
                        Doba rozvozu
                    </div>
                    <div
                        className={
                            'border-t border-b xs:border border-black rounded-none  xs:rounded-xl mt-5 p-6 justify-center flex-row '
                        }
                    >
                        {speedloData.businessHours
                            .sort((item, item2) => item.weekDay.id - item2.weekDay.id)
                            .map((item, branch) => {
                                return getFragment(branch, transformDay(item.weekDay.enum), item.openAt, item.closeAt)
                            })}
                    </div>
                </div>
            )}
        </div>
    )
}
export default React.memo(OpeningHours)
