import { PulseLoader } from 'react-spinners'

const LoaderComponent = ({ loaded, children, color = '#E5E5E5' }) => {
    if (!loaded)
        return (
            <div className={'flex justify-center align-middle place-items-center py-[10%]'}>
                <PulseLoader size={40} color={color} speedMultiplier={0.8} />
            </div>
        )
    return <>{children}</>
}

export default LoaderComponent
