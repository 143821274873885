import { useCallback, useEffect, useMemo, useState } from 'react'
import ModalContainer from '@/ui/components/modal/ModalContainer'
import Button from '@/ui/components/shared/Button'
import { OpeningHours } from '@/ui/components/footer/OpeningHours'
import { useDispatch, useSelector } from 'react-redux'
import {
    backupOrderDataSelector,
    branchIdSelector,
    orderDataZoneIdSelector,
    orderDeliveryTypeSelector,
    orderIdSelector
} from '@/selectors/selectors'
import { setBackupOldData, setOrderData, setOrderLaterClosedBranch } from '@/redux/actions/orderAction'
import { WarePriceTypeEnum } from '@/constants/constants'
import { callGraphQlClient } from '@/functions/loaderGraphQl'
import { deliverySlotsQuery } from '@/graphql/query/deliverySlotsQuery'
import LoaderComponent from '@/ui/components/shared/LoaderComponent'
import { transferOrder } from '@/functions/orderControl/orderControl'
import { captureException } from '@sentry/nextjs'
import { useRouter } from 'next/router'

const BranchClosedModal = ({ layoutData, shouldRedirect = true, closeModal }) => {
    const router = useRouter()
    const dispatch = useDispatch()

    const [deliverySlots, setDeliverySlots] = useState({
        loading: true,
        data: null
    })

    const branchId = useSelector(branchIdSelector)
    const zoneId = useSelector(orderDataZoneIdSelector)
    const orderID = useSelector(orderIdSelector)
    const orderDeliveryType = useSelector(orderDeliveryTypeSelector)
    const backupOrderData = useSelector(backupOrderDataSelector)

    useEffect(() => {
        if (branchId !== null) {
            const variables = {
                companyBranch: branchId,
                deliveryType: orderDeliveryType.text === WarePriceTypeEnum.INHOUSE ? 'PICKUP' : 'MESSENGER',
                zoneId: zoneId
            }

            callGraphQlClient(deliverySlotsQuery, variables, dispatch)
                .then(res => {
                    setDeliverySlots({
                        loading: false,
                        data: res.deliverySlots
                    })
                })
                .catch(err => {
                    captureException(err)
                    console.error(err)
                })
        }
    }, [branchId, zoneId, orderDeliveryType.type])

    const redirectToHP = useCallback(() => {
        if (shouldRedirect) {
            let url = '/'
            router.replace(url)
        } else {
            if (backupOrderData) {
                dispatch(setOrderData(backupOrderData))
                dispatch(setBackupOldData(null))
                transferOrder(
                    orderID,
                    backupOrderData.id,
                    backupOrderData.gps,
                    backupOrderData.formattedAddress,
                    orderDeliveryType.text,
                    dispatch
                )
            }
            router.back()
        }
    }, [backupOrderData, dispatch, orderDeliveryType.text, orderID, shouldRedirect])

    const continueWithOrder = useCallback(() => {
        dispatch(setOrderLaterClosedBranch(true))
        closeModal()
    }, [closeModal, dispatch])

    const label = useMemo(() => {
        let buttonTitle = 'Prohlédnout nabídku'
        if (deliverySlots) {
            if (!deliverySlots.loading) {
                if (deliverySlots.data && deliverySlots.data.length !== 0) buttonTitle = 'Chci si objednat na později'
            }
        }
        return buttonTitle
    }, [deliverySlots])

    const subtitle = useMemo(() => {
        let buttonTitle = 'Je nám líto, ale momentálně není možné si objednat.'
        if (deliverySlots) {
            if (!deliverySlots.loading) {
                if (deliverySlots.data && deliverySlots.data.length !== 0)
                    buttonTitle = 'Momentálně přijímáme pouze předobjednávky.'
            }
        }
        return buttonTitle
    }, [deliverySlots])

    return (
        <ModalContainer title="Pobočka má zavřeno" subtitleIcon={true} subtitle={subtitle} closeVisible={false}>
            <div className={'py-4 pb-4'}>
                <div className={'flex justify-center pb-8'}>
                    <OpeningHours data={layoutData && layoutData.data} />
                </div>
                <LoaderComponent loaded={!deliverySlots.loading}>
                    <div className={'gap-x-4 flex justify-center'}>
                        <Button title="Vybrat jinou pobočku" onClick={redirectToHP} variant={'ghost'} />
                        <Button title={label} onClick={continueWithOrder} />
                    </div>
                </LoaderComponent>
            </div>
        </ModalContainer>
    )
}

export default BranchClosedModal
