import { gql } from 'graphql-request'

export const deliverySlotsQuery = gql`
    query deliverySlots($companyBranch: ID!, $deliveryType: DeliveryTypeEnum!, $zoneId: ID) {
        deliverySlots(companyBranch: $companyBranch, deliveryType: $deliveryType, zone: $zoneId) {
            id
            startAt
            endAt
            capacity
            capacityTaken
            isFull
        }
    }
`
