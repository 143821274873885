import { BaseLayers, Map, Marker, MarkerLayer, MouseControl } from 'react-mapycz'
import { Fragment } from 'react'
import LoaderComponent from '@/ui/components/shared/LoaderComponent'

export const CustomMap = ({ isOnBrand, regions, coordinates }) => {
    function generateMapMarkers() {
        let markers = []
        {
            regions &&
                regions.map(region => {
                    region.branches.map(branch => {
                        markers.push(
                            <Fragment key={branch.id}>
                                <Marker
                                    coords={{ lat: branch.lat, lng: branch.long }}
                                    card={{
                                        header: '<strong>Pobočka</strong>',
                                        body: branch.name
                                    }}
                                />
                            </Fragment>
                        )
                    })
                })
        }
        return markers
    }

    if (isOnBrand && !regions) return <LoaderComponent />
    if (!isOnBrand && !coordinates) return <LoaderComponent />
    return (
        <div className={'mt-7 lg:mt-0 xs:rounded-xl overflow-hidden'}>
            {isOnBrand ? (
                <Map baseLayer={BaseLayers.SMART_BASE} zoom={7} baseLayers={[22]}>
                    <MouseControl zoom={true} pan={true} wheel={true} />
                    <MarkerLayer>{generateMapMarkers()}</MarkerLayer>
                </Map>
            ) : (
                <Map baseLayer={BaseLayers.SMART_BASE} center={{ lat: coordinates.lat, lng: coordinates.long }}>
                    <MouseControl zoom={true} pan={true} wheel={true} />
                    <MarkerLayer>
                        <Marker coords={{ lat: coordinates.lat, lng: coordinates.long }} />
                    </MarkerLayer>
                </Map>
            )}
        </div>
    )
}
export default CustomMap
