const CloseBigger = props => (
    <svg width={28} height={28} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M14 0a14 14 0 1 0 0 28 14 14 0 0 0 0-28Zm4.704 17.22a1.05 1.05 0 0 1-1.484 1.484L14 15.484l-3.22 3.22a1.023 1.023 0 0 1-.742.308 1.037 1.037 0 0 1-.742-.308 1.05 1.05 0 0 1 0-1.484l3.22-3.22-3.22-3.22a1.05 1.05 0 0 1 1.484-1.484l3.22 3.22 3.22-3.22a1.05 1.05 0 0 1 1.484 1.484L15.484 14l3.22 3.22Z"
            fill="#000"
        />
    </svg>
)

export default CloseBigger
