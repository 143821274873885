import Image from 'next/image'

const FooterSocials = () => {
    return (
        <div className={'flex flex-row justify-center mt-5 z-0'}>
            <div className={'flex-1 items-center text-center'}>
                <a
                    href="https://www.facebook.com/people/turbo_pizza_official/100038918272520/"
                    className={'inline-block mx-2'}
                    target="_blank"
                    rel="noreferrer"
                >
                    <Image src={require('../../../public/ic_fb.svg')} width={20} height={23} alt={'ic_fb.svg'} />
                </a>
                <a
                    href="https://www.instagram.com/turbo_pizza_official/"
                    className={'inline-block mx-2'}
                    target="_blank"
                    rel="noreferrer"
                >
                    <Image src={require('../../../public/ic_insta.svg')} width={20} height={23} alt={'ic_insta.svg'} />
                </a>
            </div>
        </div>
    )
}
export default FooterSocials
