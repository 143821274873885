import { scroller } from 'react-scroll'
import { setScrollElement } from '@/redux/actions/scrollAction'

export function performScroll(scrollToElement) {
    scroller.scrollTo(scrollToElement, {
        duration: 1000,
        delay: 0,
        smooth: 'easeOutQuart',
        offset: -80 // Scrolls to element + 50 pixels down the page
    })
}

export function setupScrollElement(dispatch, scrollToElement) {
    dispatch(setScrollElement(scrollToElement))
}
