import GDPR from '/public/gdpr.pdf'
import Vop from '/public/vop.pdf'

const VOP = () => {
    return (
        <div className={'flex flex-col md:flex-row justify-center pt-4 text-center'}>
            <a
                target={'_blank'}
                href={Vop}
                className={'font-libre_franklin hover:cursor-pointer pr-2'}
                rel="noreferrer"
            >
                Všeobecné obchodní podmínky
            </a>
            <a
                target={'_blank'}
                href={GDPR}
                className={'font-libre_franklin hover:cursor-pointer px-2'}
                rel="noreferrer"
            >
                Zásady ochrany osobních údajů
            </a>
            <a
                target={'_blank'}
                href="https://cdn.deepvision.cloud/turbopizza/alergeny.pdf"
                className={'font-libre_franklin hover:cursor-pointer pl-2'}
                rel="noreferrer"
            >
                Seznam alergenů
            </a>
        </div>
    )
}

export default VOP
