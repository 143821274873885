import { Fragment } from 'react'
import ArrowRight from '../../icons/ArrowRight'

const Arrows = ({ transform, numberOfLines, color }) => {
    if (numberOfLines === undefined) numberOfLines = 3

    const renderNumberOfLines = () => {
        let lines = []
        for (let i = 0; i < numberOfLines; i++) {
            lines.push(
                <Fragment key={i}>
                    <div className={`flex ${!transform ? 'flex-row' : 'flex-row'} w-auto`}>
                        <ArrowRight color={color} />
                    </div>
                </Fragment>
            )
        }
        return lines
    }

    return <div className={'overflow-hidden'}>{renderNumberOfLines()}</div>
}
export default Arrows
