const ArrowRight = props => {
    /* <svg width={21} height={21} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M.257 20.667V0L21 10.333.257 20.667Z" fill={props.color} />
    </svg>*/
    const patternId = 'biggerTriangles' + Math.floor(Math.random() * 1000000000)

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="21">
            <defs>
                <pattern id={patternId} patternUnits="userSpaceOnUse" width="21" height="21" x="100%">
                    <path d="M.257 20.667V0L21 10.333.257 20.667Z" fill={props.color} />
                </pattern>
            </defs>
            <rect width="99.8%" height="100%" fill={`url(#${patternId})`} />
        </svg>
    )
}

export default ArrowRight
