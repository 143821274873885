import axios from 'axios'

export const AxiosClient = axios.create({
    baseURL: process.env.NEXT_PUBLIC_APP_BE_ENDPOINT,
    headers: {
        WebToken: process.env.NEXT_PUBLIC_APP_WEB_TOKEN,
        'Content-Type': 'application/json'
    },
    credentials: 'include'
})
