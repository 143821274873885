import React, { useEffect, useState } from 'react'
import ArrowsRightComponent from '@/ui/components/arrows/ArrowsRight'
import ScalableSvg from '@/ui/components/shared/ScalableSvg'

const TitleBlock = ({ title, subtitle, numberOfLines, color, icon, arrows = true }) => {
    const [width, setWidth] = useState()

    function handleWindowSizeChange() {
        setWidth(window.innerWidth)
    }

    useEffect(() => {
        if (window) setWidth(window.innerWidth)
        window.addEventListener('resize', handleWindowSizeChange)
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange)
        }
    }, [])

    const isMobile = width < 400
    if (!color) color = '#E30A18'
    return (
        <div
            className={`grid justify-center xs:justify-between text-center items-center pt-7 md:pt-14 md:mb-6`}
            style={{ gridTemplateColumns: arrows && !isMobile ? `1fr auto 1fr` : `1fr` }}
        >
            {arrows && (
                <>
                    <div className={'hidden xs:inline-block xl:hidden flex-grow flex-shrink'}>
                        <ArrowsRightComponent numberOfLines={numberOfLines} color={color} />
                    </div>
                </>
            )}

            <div className={'flex flex-grow  flex-col items-center justify-center items-center px-12'}>
                {icon && <ScalableSvg icon={icon} />}
                <div className={'fk-screamer-title leading-tight '}>{title}</div>
                {subtitle && <div className={'font-libre_franklin text-sm lg:text-xl pt-3 lg:pt-5'}>{subtitle}</div>}
            </div>
            {arrows && (
                <>
                    <div className={'hidden rotate-180 xs:inline-block xl:hidden flex-grow flex-shrink'}>
                        <ArrowsRightComponent numberOfLines={numberOfLines} color={color} />
                    </div>
                </>
            )}
        </div>
    )
}

export default React.memo(TitleBlock)
