import { gql } from 'graphql-request'

export const branchDetailQuery = gql`
    query companyBranches($branchId: ID!) {
        companyBranch: companyBranch(id: $branchId) {
            id
            name
            phone
            delayedDeliveryEnabled
            gps {
                latitude
                longitude
            }
            address {
                emails {
                    email
                    description
                }
                location {
                    city
                    street
                    houseNumber
                    postCode
                }
            }
            paymentMethods(warePriceType: DELIVERY, orderOrigin: MINISITE) {
                paymentMethod {
                    description
                }
            }
            defaultDeliveryTimeEnabled
            defaultPickupTimeEnabled
            defaultDeliveryTime: currentDeliveryTime
            defaultPickupTime: currentPickupTime
        }

        businessHours(companyBranch: $branchId) {
            openAt
            closeAt

            weekDay {
                enum
                id
            }
        }
    }
`
