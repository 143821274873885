import Image from 'next/image'

const FooterInformation = ({ imageSource, text }) => {
    return (
        <div className={`min-w-[40%] flex-1 items-center text-center ${!text ? 'px-[11px]' : ''}`}>
            <Image src={require('../../../../public/' + imageSource)} width={20} height={23} alt={imageSource} />
            {text && (
                <div
                    className={
                        'mt-[10px] lg:mt-[20px] font-libre_franklin text-[12px] md:text-[14px] lg:text-[16px] xl:text-[20px] '
                    }
                >
                    {text}
                </div>
            )}
        </div>
    )
}
export default FooterInformation
