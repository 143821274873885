import React from 'react'
import CloseBigger from '@/ui/icons/CloseBigger'
import { Info } from 'react-feather'

const ModalContainer = ({ children, title, subtitle, subtitleIcon, setModalVisibility, closeVisible = true }) => {
    return (
        <div
            className={`overscroll-contain w-full h-full fixed left-0 top-0 bottom-0 bg-white md:bg-[rgba(0,0,0,0.6)]  z-[60] md:px-4 md:px-0`}
        >
            <div className={'md:container md:mx-auto  md:my-[5%]  relative '}>
                <div className={'max-h-[80vh] flex flex-col z-50 bg-white text-center md:rounded-xl p-4 md:p-6'}>
                    <div className={'fk-screamer-title '}>{title}</div>
                    {subtitle && (
                        <div className={'font-libre_franklin px-8'}>
                            <div className={'flex justify-center items-center mt-4'}>
                                {subtitleIcon && <Info />}
                                <div className={'ml-2 md:ml-4 text-md'}>{subtitle}</div>
                            </div>
                        </div>
                    )}
                    {children}
                    {closeVisible && (
                        <div
                            className={'absolute right-3 top-3 py-4 md:top-6 md:right-6 md:py-0 hover:cursor-pointer'}
                            onClick={() => setModalVisibility(false)}
                        >
                            <CloseBigger />
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default React.memo(ModalContainer)
