import FooterInformation from './FooterInformation'

export function formatPhoneNumber(phoneNumber) {
    let formattedPhone = ''

    try {
        formattedPhone = phoneNumber.substring(0, 4)

        const number = phoneNumber.substring(4, phoneNumber.length)
        for (let i = 0; i <= number.length; i++) {
            if (i % 3 === 0) formattedPhone += number.substring(i - 3, i) + ' '
        }
    } catch (e) {
        return phoneNumber
    }

    return formattedPhone
}

const FooterInformationBlock = ({ location, phoneNumber, email, payments }) => {
    const formatPaymenthMethods = data => {
        let formattedPayMethods = ''
        data.map(method => {
            if (!formattedPayMethods.includes(method.paymentMethod.description)) {
                formattedPayMethods += method.paymentMethod.description + ', '
            }
        })

        return formattedPayMethods.substring(0, formattedPayMethods.length - 2).toLocaleLowerCase()
    }

    return (
        <div className={`mx-3 mt-8 flex flex-wrap flex-cols-2 gap-x-3 gap-y-7 justify-center content-center lg:mt-0`}>
            <FooterInformation imageSource={'ic_place.svg'} text={location} />
            <FooterInformation imageSource={'ic_phone.svg'} text={phoneNumber} />
            <FooterInformation imageSource={'ic_posta.svg'} text={email} />
            {payments && <FooterInformation imageSource={'ic_card.svg'} text={formatPaymenthMethods(payments)} />}
        </div>
    )
}
export default FooterInformationBlock
